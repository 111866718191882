import styled from 'styled-components'

const StyledFeaturedInLogo = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  gap: 80px 40px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 56px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 40px 20px;
    margin-top: 48px;
  }

  img {
    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 28px;
    }
  }
`

export const FeaturedInLogos = () => {
  return (
    <StyledFeaturedInLogo>
      <li>
        <a
          href="https://www.forbes.com/sites/annahaines/2021/07/23/this-diet-is-changing-the-way-we-think-about-nutrition/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/forbes.svg" alt="Forbes" width="128" height="32" />
        </a>
      </li>

      <li>
        <a
          href="https://www.theguardian.com/lifeandstyle/2022/jun/10/the-diet-that-is-right-for-you-putting-a-personalised-nutrition-app-to-the-test"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/guardian.svg" alt="The Guardian" width="148" height="49" />
        </a>
      </li>

      <li>
        <a
          href="https://www.newscientist.com/article/2246056-there-is-no-perfect-diet-that-works-for-every-metabolism-or-body-type/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/new-scientist.svg" alt="New Scientist" width="165" height="23" />
        </a>
      </li>

      <li>
        <a href="https://www.economist.com/nutrition-pod" target="_blank" rel="noreferrer">
          <img src="/assets/img/logos/economist.svg" alt="The Economist" width="178" height="54" />
        </a>
      </li>

      <li>
        <a
          href="https://www.thetimes.co.uk/article/inside-the-zoe-health-app-with-its-220k-waiting-list-k6dkj66sh"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/times.svg" alt="The Times" width="235" height="26" />
        </a>
      </li>

      <li>
        <a
          href="https://www.telegraph.co.uk/health-fitness/nutrition/diet/lost-stone-without-ever-feeling-hungry/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/img/logos/telegraph.svg" alt="The Telegraph" width="201" height="33" />
        </a>
      </li>
    </StyledFeaturedInLogo>
  )
}
